import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsersCog,
  faStream,
  faUsers,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./style.scss";
import { AppName, appVersion } from "../../config";

const links = [
  {
    icon: faUsers,
    color: "#f1cc05",
    title: "Gebruikers",
    link: "/users",
  },
  {
    icon: faStream,
    color: "#4ba989",
    title: "Deals",
    link: "/deals",
  },
  {
    icon: faUsersCog,
    color: "#dd7e5e",
    title: "Klanten",
    link: "/customers",
  },
  {
    icon: faUser,
    color: "#ddaa4f",
    title: "Leden",
    link: "/members",
  },
];

const Navigation = () => {
  const [navopen, setNavopen] = useState(false);

  return (
    <div className={`navigation-root ${navopen ? "open" : null}`}>
      <div className="navigation-root__head">
        <NavLink className="navigation-root__head__link" to="/">
          <span>
            {AppName}
            <br />
            <span className="navigation-root__head__link__version">
              v{appVersion}
            </span>
          </span>
        </NavLink>
      </div>
      <nav className="navigation-root__nav">
        <ul>
          {links.map((link, i) => (
            <li key={i}>
              <NavLink
                to={link.link}
                className="navigation-root__nav__link"
                activeClassName="navigation-root__nav__link__active"
              >
                <FontAwesomeIcon icon={link.icon} color={link.color} />
                <span className="navigation-root__nav__link__title">
                  {link.title}
                </span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;

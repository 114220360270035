import React from "react";
import { connect } from "react-redux";
import CustomSnack from "../CustomSnack";
import { hideSnack } from "../../../redux/actions/app";

const Snack = (props) => {
  const { message, messageType, delay } = props;
  return (
    <CustomSnack
      variant={messageType || "error"}
      message={message ? message : undefined}
      delay={delay}
      onClose={() => props.doHideSnack()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    message: state.app.snack.message,
    messageType: state.app.snack.messageType,
    delay: state.app.snack.delay,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doHideSnack: () => {
      dispatch(hideSnack());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Snack);

import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loader from "../../components/Loader";
import HashedRoute from "../../HashedRoute";

const Dashboard = lazy(() => import("./Dashboard"));
const Customers = lazy(() => import("./Customers"));
const Members = lazy(() => import("./Members"));
const CustomersDetail = lazy(() => import("./CustomersDetail"));
const Users = lazy(() => import("./Users"));
const UsersDetail = lazy(() => import("./UsersDetail"));
const Deals = lazy(() => import("./Deals"));
const DealDetail = lazy(() => import("./DealDetail"));
const routes = [
  {
    path: "/users",
    component: Users,
    title: "Gebruikers",
  },
  {
    path: "/users/new",
    component: UsersDetail,
    title: "Nieuwe Gebruiker",
  },
  {
    path: "/users/detail/:id",
    component: UsersDetail,
    title: "Gebruiker Detail",
  },
  {
    path: "/customers/new",
    component: CustomersDetail,
    title: "Nieuwe Klant",
  },
  {
    path: "/customers/detail/:id",
    component: CustomersDetail,
    title: "Klant Detail",
  },
  {
    path: "/customers",
    component: Customers,
    title: "Klanten",
  },
  {
    path: "/members",
    component: Members,
    title: "Leden",
  },
  {
    path: "/deals",
    component: Deals,
    title: "Deals",
  },
  {
    path: "/deals/new",
    component: DealDetail,
    title: "Nieuwe Deal",
  },
  {
    path: "/deals/detail/:id",
    component: DealDetail,
    title: "Deal Detail",
  },
];

const Router = (props) => {
  const createRoute = (path, Component, index) => {
    return (
      <Route
        exact
        key={index}
        path={path}
        render={(props) => <Component {...props} />}
      />
    );
  };

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routes.map((item, i) => {
          return createRoute(item.path, item.component, i);
        })}
        <HashedRoute path="/" component={Users} />
      </Switch>
    </Suspense>
  );
};

export default Router;

export const getRouteTitle = (path) => {
  for (var i = 0; i < routes.length; i++) {
    const route = routes[i];
    if (
      path.indexOf(route.pathForTitle ? route.pathForTitle : route.path) === 0
    ) {
      return route.title;
    }
  }
  return "Geen titel";
};

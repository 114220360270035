import { LIST_MEMBERS, SET_MEMBERS } from "../actions/members/types";

const initialState = {
  isFetching: false,
  members: [],
};

const members = (state = initialState, action) => {
  switch (action.type) {
    case LIST_MEMBERS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        members: action.members || state.members,
      };
    case SET_MEMBERS:
      return {
        ...state,
        isFetching: false,
        members: action.members,
      };
    default:
      return state;
  }
};

export default members;

import {
  HIDE_SNACK,
  SHOW_SNACK,
  START_FETCHING,
  END_FETCHING,
  START_OFFLINE,
  END_OFFLINE,
} from "./types";

export function startFetching() {
  return {
    type: START_FETCHING,
  };
}

export function endFetching() {
  return {
    type: END_FETCHING,
  };
}

export function switchOffline() {
  return {
    type: navigator.onLine ? END_OFFLINE : START_OFFLINE,
  };
}

export const showSnack = (message, messageType, delay, i18n) => {
  return {
    type: SHOW_SNACK,
    message,
    messageType,
    delay,
    i18n,
  };
};

export const hideSnack = () => {
  return {
    type: HIDE_SNACK,
  };
};

exports.getTodayUTC = () => {
  var now = new Date();
  return Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
};

exports.dateAdd = (date, days) => {
  if (days && days > 0) {
    return new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
  } else {
    return date;
  }
};

exports.daysBetween = (first, second) => {
  return Math.round((second - first) / (1000 * 60 * 60 * 24));
};

exports.createUTCDate = (date) => {
  if (date) {
    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  }
  return null;
};

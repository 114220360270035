import React from "react";
import Header from "../Header";
import Navigation from "../Navigation";
import Page from "../Page";
import "./style.scss";

const Layout = ({ children }) => {
  return (
    <div className="layout-root">
      <div className="layout-root__main">
        <Navigation />
        <section id="main" className="layout-root__main__page">
          <Header />
          <Page>{children}</Page>
        </section>
      </div>
    </div>
  );
};

export default Layout;

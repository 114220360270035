import { convertToRaw, EditorState } from "draft-js";
import { createUTCDate } from "../../utils/date";
import {
  LIST_DEALS,
  UPDATE_DEAL,
  SET_DEAL,
  SET_DEALS,
  SAVE_DEAL,
  REMOVE_DEAL,
} from "../actions/deals/types";

const initialState = {
  isFetching: false,
  deals: [],
  dealDetail: {},
};

const deals = (state = initialState, action) => {
  switch (action.type) {
    case LIST_DEALS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        deals: action.deals || state.deals,
      };
    case REMOVE_DEAL:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case UPDATE_DEAL:
      return {
        ...state,
        dealDetail: {
          ...state.dealDetail,
          [action.field]: action.value,
          validFromUTC:
            action.field === "validFrom" &&
            action.value &&
            action.value.length === 10 &&
            new Date(action.value).getTime()
              ? createUTCDate(new Date(action.value))
              : state.dealDetail.validFromUTC,
          validToUTC:
            action.field === "validTo" &&
            action.value &&
            action.value.length === 10 &&
            new Date(action.value).getTime()
              ? createUTCDate(new Date(action.value))
              : state.dealDetail.validToUTC,
          longDescription:
            action.field === "longDescriptionState"
              ? convertToRaw(action.value.getCurrentContent())
              : state.dealDetail.longDescription,
        },
      };
    case SET_DEAL:
      return {
        ...state,
        isFetching: false,
        dealDetail: action.dealDetail,
      };
    case SET_DEALS:
      return {
        ...state,
        isFetching: false,
        deals: action.deals,
      };
    case SAVE_DEAL:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default deals;

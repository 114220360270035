import {
  UPDATE_USER,
  SET_USER,
  SET_USERS,
  LIST_USERS,
  SAVE_USER,
} from "../actions/users/types";

export const initialState = {
  isFetching: false,
  users: [],
  userDetail: {},
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          [action.field]: action.value,
          passwordChanged:
            action.field === "password"
              ? true
              : state.userDetail.passwordChanged,
          emailChanged:
            action.field === "email" ? true : state.userDetail.emailChanged,
          usernameChanged:
            action.field === "username"
              ? true
              : state.userDetail.usernameChanged,
        },
      };
    case SET_USER:
      return {
        ...state,
        isFetching: false,
        userDetail: action.userDetail,
      };
    case SET_USERS:
      return {
        ...state,
        isFetching: false,
        users: action.users,
      };
    case SAVE_USER:
      return { ...state, isFetching: action.isFetching || false };
    case LIST_USERS:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default users;

import React from "react";
import Layout from "../../components/Layout";
import Router from "./Router";

const Main = () => {
  return (
    <div className="main-root">
      <Layout>
        <section id="main" className="main-root__inner">
          <Router />
        </section>
      </Layout>
    </div>
  );
};

export default Main;

import { combineReducers } from "redux";
import app from "./app";
import auth from "./auth";
import customers from "./customers";
import deals from "./deals";
import users from "./users";
import members from "./members";

const appReducer = combineReducers({
  app,
  auth,
  customers,
  deals,
  users,
  members,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    const { app, layout } = state;
    state = { app, layout };
  }
  return appReducer(state, action);
};

export default rootReducer;

import React from "react";
import { withRouter } from "react-router-dom";
import { getTokenUser } from "../../auth/auth";
import { getRouteTitle } from "../../pages/Main/Router";
import "./style.scss";

const Header = ({ location }) => {
  const user = getTokenUser();
  return (
    <header className="header-root">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <div>
            <span>{getRouteTitle(location.pathname)}</span>
          </div>
          <div className="header-root__user">
            <span className="header-root__user__name">{user.fullName}</span>
            <button className="btn btn-outline-danger" type="submit">
              Afmelden
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default withRouter(Header);
